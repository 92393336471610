import React from "react";

import {
  Section,
  SectionDivider,
  SectionTitle,
} from "../../styles/GlobalComponents";
import {
  Box,
  Boxes,
  BoxNum,
  BoxText,
  Sertificates,
  SertificateLogo,
  SertificateTitle,
  SertificateSubTitle,
  SertificateLink,
  Sertificate,
} from "./AcomplishmentsStyles";

import meta from "../../Assets/meta.jpeg";
import udasity from "../../Assets/udasity.jpeg";

import Button from "../../styles/GlobalComponents/Button";

const data = [
  { number: 2.5, text: "Years of Experience" },
  { number: 20, text: "Completed Projects" },
  { number: 10, text: "Students" },
  { number: 140, text: "Github Repositories" },
  { number: 500, text: "Github Stars" },
];

const certificateData = [
  {
    img: meta,
    title: "Front-End Development",
    subTitle: "Issued Jun 2023",
    link: "https://www.coursera.org/account/accomplishments/certificate/3YK3BLDE23EF",
  },
  {
    img: meta,
    title: "Programming with JavaScript",
    subTitle: "Issued Jun 2023",
    link: "https://www.coursera.org/account/accomplishments/certificate/RCYGKWPGC43M",
  },
  {
    img: meta,
    title: "HTML and CSS in depth",
    subTitle: "Issued Jun 2023",
    link: "https://www.coursera.org/account/accomplishments/certificate/L3A4CWSV5N2G",
  },
  {
    img: meta,
    title: "Version Control",
    subTitle: "Issued Jun 2023",
    link: "https://www.coursera.org/account/accomplishments/certificate/JWBLBCQ844KX",
  },
  {
    img: udasity,
    title: "Full Stack Developer",
    subTitle: "Issued December 2022",
    link: "https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/cf0cf53d-d365-42c8-a448-382649de8ad1.pdf",
  },
  {
    img: udasity,
    title: "Front-End Development",
    subTitle: "Issued December 2022",
    link: "https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/191d5393-119b-4478-89ee-05fdeb2dd2ea.pdf",
  },
  {
    img: udasity,
    title: "Data Analysis",
    subTitle: "Issued December 2022",
    link: "https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/7361ee33-9402-4911-8f1e-c2f9e69290dc.pdf",
  },
  {
    img: udasity,
    title: "Android Development",
    subTitle: "Issued December 2022",
    link: "https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/44b0a32c-92dc-4e43-a51e-c8ca721b7329.pdf",
  },
];

const Acomplishments = () => (
  <Section id="personal">
    <div data-aos="fade-down">
      <SectionDivider />
      <div style={{ marginTop: "10px" }} />
      <SectionTitle>Personal Achievements</SectionTitle>
    </div>

    <Boxes>
      {data.map((card, index) => (
        <div data-aos={index % 2 === 1 ? "fade-left" : "fade-right"}>
          <Box key={index}>
            <BoxNum>{`${card.number}+`}</BoxNum>
            <BoxText>{card.text}</BoxText>
          </Box>
        </div>
      ))}
    </Boxes>

    <div data-aos="fade-down">
      <SectionDivider />
      <div style={{ marginTop: "10px" }} />
      <SectionTitle>Certificates</SectionTitle>
    </div>

    <Sertificates id="certificates">
      {certificateData.map((certificate, index) => (
        <div data-aos="fade-up">
          <Sertificate>
            <SertificateLogo>
              <img src={certificate.img} />
            </SertificateLogo>
            <SertificateTitle>{certificate.title}</SertificateTitle>
            <SertificateSubTitle>{certificate.subTitle}</SertificateSubTitle>

            <a
              href={certificate.link}
              target="_blank"
              style={{ color: "#fff" }}
            >
              <SertificateLink>View certificate</SertificateLink>
            </a>
          </Sertificate>
        </div>
      ))}
    </Sertificates>
  </Section>
);

export default Acomplishments;
