import styled from "styled-components";

export const Boxes = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin: 24px 0 40px;

  @media ${(props) => props.theme.breakpoints.md} {
    gap: 16px;
    margin: 20px 0 32px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    max-width: 500px;
    margin: 24px auto;
  }
`;

export const Box = styled.div`
  background: #212d45;
  border-radius: 12px;
  height: 144px;
  padding: 24px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    height: 210px;
  }

  @media ${(props) => props.theme.breakpoints.md} {
    height: 135px;
    padding: 16px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    height: 110px;
    padding: 12px;

    &:nth-child(2n) {
      grid-row: 2;
    }
  }
`;
export const BoxNum = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.md} {
    font-size: 28px;
    line-height: 32px;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const BoxText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.75);

  @media ${(props) => props.theme.breakpoints.md} {
    font-size: 16px;
    line-height: 20px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Join = styled.div`
  display: flex;
  max-width: 1040px;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;

  @media ${(props) => props.theme.breakpoints.md} {
    display: flex;
    justify-content: center;
    padding-bottom: 64px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
  }
`;

export const JoinText = styled.h5`
  display: flex;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.5);

  @media ${(props) => props.theme.breakpoints.md} {
    line-height: 32px;
    font-size: 20px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 16px;
  }
`;

export const IconContainer = styled.div`
  display: flex;

  @media ${(props) => props.theme.breakpoints.sm} {
    width: 160px;
    justify-content: space-between;
  }
`;

// Sertificates

export const Sertificates = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;

  @media ${(props) => props.theme.breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    grid-template-columns: 1fr;
  }
`;

export const Sertificate = styled.div`
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #fff;
  transition: all 0.1s;

  &:hover {
    box-shadow: rgba(255, 255, 255, 1) 0px 1.5px 1.5px,
      rgba(255, 255, 255, 1) 0px 0px 1.5px 1.5px;
    transform: scale(1.002);
  }

  @media ${(props) => props.theme.breakpoints.md} {
    height: 230px;
    padding: 15px;
    border-radius: 15px;
  }

  @media ${(props) => props.theme.breakpoints.md} {
    border-radius: 10px;
    height: 210px;
    gap: 4px;
  }
`;

export const SertificateLogo = styled.div`
  img {
    border-radius: 50%;
  }

  @media ${(props) => props.theme.breakpoints.md} {
    img {
      width: 80px;
    }
  }
`;

export const SertificateTitle = styled.p`
  font-size: 24px;

  @media ${(props) => props.theme.breakpoints.md} {
    font-size: 20px;
  }
`;

export const SertificateSubTitle = styled.p`
  @media ${(props) => props.theme.breakpoints.md} {
    font-size: 14px;
  }
`;

export const SertificateLink = styled.div`
  color: #fff;
  text-decoration: none;
  padding: 7px 10px;
  border: 1px solid #6aa7e6;
  width: fit-content;
  border-radius: 5px;

  &:hover {
    transform: scale(1.02);
  }
`;
