import React, { useState, useRef, useEffect } from "react";

import {
  CarouselButton,
  CarouselButtonDot,
  CarouselButtons,
  CarouselContainer,
  CarouselItem,
  CarouselItemImg,
  CarouselItemText,
  CarouselItemTitle,
  CarouselMobileScrollNode,
  ExperienceCard,
  ExperienceCardKompany,
  ExperienceCardKompanyLogo,
  ExperienceCardKompanyInfo,
  ExperienceCardKompanyInfoNames,
  ExperienceCardKompanyInfoNameKompany,
  ExperienceCardKompanyInfoJobPosition,
  ExperienceCardKompanyInfoTimeLine,
  ExperienceCardWordText,
  ExperienceCardKompanyInfoTimeLineMobile,
} from "./TimeLineStyles";

import magicLogo from "../../Assets/MagicLogo.jpg";
import whiteLineLogo from "../../Assets/whiteLine.jpeg";
import magicsoftAcademy from "../../Assets/magicsoftAcademy.jpg";
import exadotLogo from "../../Assets/exadot_logo.jpeg";

import {
  Section,
  SectionDivider,
  SectionText,
  SectionTitle,
} from "../../styles/GlobalComponents";
import { TimeLineData } from "../../constants/constants";

const TOTAL_CAROUSEL_COUNT = TimeLineData.length;

const Timeline = () => {
  const [activeItem, setActiveItem] = useState(0);
  const carouselRef = useRef();

  const scroll = (node, left) => {
    return node.scrollTo({ left, behavior: "smooth" });
  };

  const handleClick = (e, i) => {
    e.preventDefault();

    if (carouselRef.current) {
      const scrollLeft = Math.floor(
        carouselRef.current.scrollWidth * 0.7 * (i / TimeLineData.length)
      );

      scroll(carouselRef.current, scrollLeft);
    }
  };

  const handleScroll = () => {
    if (carouselRef.current) {
      const index = Math.round(
        (carouselRef.current.scrollLeft /
          (carouselRef.current.scrollWidth * 0.7)) *
          TimeLineData.length
      );

      setActiveItem(index);
    }
  };

  // snap back to beginning of scroll when window is resized
  // avoids a bug where content is covered up if coming from smaller screen
  useEffect(() => {
    const handleResize = () => {
      scroll(carouselRef.current, 0);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <Section id="experience">
      <div data-aos="fade-down">
        <SectionDivider />
        <div style={{ marginTop: "10px" }} />
        <SectionTitle>Experience</SectionTitle>
      </div>

      <div data-aos="fade-up">
        <ExperienceCard>
          <ExperienceCardKompanyInfoTimeLineMobile>
            Jan, 2023 - Present
          </ExperienceCardKompanyInfoTimeLineMobile>
          <ExperienceCardKompany>
            <ExperienceCardKompanyLogo>
              <img src={magicLogo} />
            </ExperienceCardKompanyLogo>

            <ExperienceCardKompanyInfo>
              <ExperienceCardKompanyInfoNames>
                <ExperienceCardKompanyInfoJobPosition>
                  Software Engineer
                </ExperienceCardKompanyInfoJobPosition>

                <ExperienceCardKompanyInfoNameKompany>
                  Magic Soft
                  <span>Full-time</span>
                </ExperienceCardKompanyInfoNameKompany>
              </ExperienceCardKompanyInfoNames>
              <ExperienceCardKompanyInfoTimeLine>
                Jan, 2023 - Present
              </ExperienceCardKompanyInfoTimeLine>
            </ExperienceCardKompanyInfo>
          </ExperienceCardKompany>
          <ExperienceCardWordText>
            <ul>
              <li>
                <div />{" "}
                <a
                  href="https://iqro-quran.com/"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Project - Iqro Qur'an
                </a>
              </li>
              <li>
                <div /> I was a lead of the team in this project and my
                responsibilities were reviewing codes. Feature-sliced design
                architechture used to build the project.
              </li>
              <li>
                <div />
                Used Clean code rules and I have a responsibility to review
                others code. To optimaze CEO I added extra meta tags.
              </li>
              <li>
                <div />
                Every word in “Qur’oni Karim” seperated to 604 pages. Every page
                contains 15 rows and each word has its own location. Everything
                was absolutely the same with actual “Qur’oni Karim”. Every word
                has its own location and I get data about its location with it
                and by seeing this I can put the word to the correct location as
                in the “Qur’on”. For instance, location would be like that
                12.14.02, here 12 is page number, 14 is a row number and 2 is a
                location of the word in the row.
              </li>
              <li>
                <div />I used different fonts for each page and when a page is
                loaded, I will find its font from list of fonts. It helps me to
                memory and performence optimization.
              </li>
              <li>
                <div />
                Fetch infinity scroll. It helps me to memory and performence
                optimization.
              </li>
              <li>
                <div />
                Audio of “Qur’on” matching word marked with segment.
              </li>
              <li>
                <div />I render every word of Tajweed as a picture. I used the
                same approach as in above to put words.
              </li>

              <li>
                <div />{" "}
                <a
                  href="https://eximerp.com/"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Project - Exim-ERP
                </a>
              </li>
              <li>
                <div /> The EximERP is the digital platform for resource
                planning optimization of Foreign Economic Activity.
              </li>
              <li>
                <div />
                The platform login process has been integrated with E-Imzo.
              </li>
              <li>
                <div />
                The platform is integrated with the Didox platform to optimize
                purchases, increase sales, improve financial control and
                document flow.
              </li>
              <li>
                <div />
                Document exchange processes and system management were
                integrated in the project through the admin panel.
              </li>

              <li>
                <div />
                Project - Medical
              </li>
              <li>
                <div /> I was a lead of the team in this project and my
                responsibilities were reviewing codes and adding Authentication
                process. I added filter for passwords and logins. Number of
                ticket and room is said in audio format.
              </li>
              <li>
                <div />
                JWT token is used to clarify user’s role and different page is
                loaded for users{" "}
              </li>
              <li>
                <div />I use socket to give queue to TV monitor and doctor’s
                page.
              </li>
            </ul>
          </ExperienceCardWordText>
        </ExperienceCard>
      </div>

      <div data-aos="fade-up">
        <ExperienceCard>
          <ExperienceCardKompanyInfoTimeLineMobile>
            Oct, 2022 - April, 2023
          </ExperienceCardKompanyInfoTimeLineMobile>

          <ExperienceCardKompany>
            <ExperienceCardKompanyLogo>
              <img src={whiteLineLogo} />
            </ExperienceCardKompanyLogo>

            <ExperienceCardKompanyInfo>
              <ExperienceCardKompanyInfoNames>
                <ExperienceCardKompanyInfoJobPosition>
                  Frontend Mentor
                </ExperienceCardKompanyInfoJobPosition>

                <ExperienceCardKompanyInfoNameKompany>
                  White Line
                  <span>Part-time</span>
                </ExperienceCardKompanyInfoNameKompany>
              </ExperienceCardKompanyInfoNames>
              <ExperienceCardKompanyInfoTimeLine>
                Oct, 2022 - April, 2023
              </ExperienceCardKompanyInfoTimeLine>
            </ExperienceCardKompanyInfo>
          </ExperienceCardKompany>
          <ExperienceCardWordText>
            <ul>
              <li>
                <div /> I taught programming to students from scratch to the
                level of being able to work independently.I shared my knowledge
                with others.
              </li>
              <li>
                <div />
                Technologies: Html, Css, Scss, Git, GitHub, Javascript, React,
                Algorithms.
              </li>
            </ul>
          </ExperienceCardWordText>
        </ExperienceCard>
      </div>

      <div data-aos="fade-up">
        <ExperienceCard>
          <ExperienceCardKompanyInfoTimeLineMobile>
            July, 2022 - September, 2022
          </ExperienceCardKompanyInfoTimeLineMobile>

          <ExperienceCardKompany>
            <ExperienceCardKompanyLogo>
              <img src={exadotLogo} />
            </ExperienceCardKompanyLogo>

            <ExperienceCardKompanyInfo>
              <ExperienceCardKompanyInfoNames>
                <ExperienceCardKompanyInfoJobPosition>
                  Frontend Developer Intern
                </ExperienceCardKompanyInfoJobPosition>

                <ExperienceCardKompanyInfoNameKompany>
                  Exadot
                  <span>Part-time</span>
                </ExperienceCardKompanyInfoNameKompany>
              </ExperienceCardKompanyInfoNames>
              <ExperienceCardKompanyInfoTimeLine>
                July, 2022 - September, 2022
              </ExperienceCardKompanyInfoTimeLine>
            </ExperienceCardKompanyInfo>
          </ExperienceCardKompany>
          <ExperienceCardWordText>
            <ul>
              <li>
                <div /> Tasted the Engineering workflow with building real life
                projects.
              </li>
              <li>
                <div /> UI solutions based on solid principles.
              </li>
            </ul>
          </ExperienceCardWordText>
        </ExperienceCard>
      </div>

      <div data-aos="fade-up">
        <ExperienceCard>
          <ExperienceCardKompanyInfoTimeLineMobile>
            Nov, 2021 - Jun, 2022
          </ExperienceCardKompanyInfoTimeLineMobile>

          <ExperienceCardKompany>
            <ExperienceCardKompanyLogo>
              <img src={magicsoftAcademy} />
            </ExperienceCardKompanyLogo>

            <ExperienceCardKompanyInfo>
              <ExperienceCardKompanyInfoNames>
                <ExperienceCardKompanyInfoJobPosition>
                  Frontend developer Intern
                </ExperienceCardKompanyInfoJobPosition>

                <ExperienceCardKompanyInfoNameKompany>
                  Magic Soft
                  <span>Full-time</span>
                </ExperienceCardKompanyInfoNameKompany>
              </ExperienceCardKompanyInfoNames>
              <ExperienceCardKompanyInfoTimeLine>
                Nov, 2021 - Jun, 2022
              </ExperienceCardKompanyInfoTimeLine>
            </ExperienceCardKompanyInfo>
          </ExperienceCardKompany>
          <ExperienceCardWordText>
            <ul>
              <li>
                <div /> Developed: Online store for selling products. A search
                engine to search users from Github. Map system for route and
                distance determination.
              </li>
              <li>
                <div />
                Implemented: all the processes of the projects, program codes,
                algorithm and operation logic with javascript, visual
                representation with react, map system with leaflet.
              </li>
            </ul>
          </ExperienceCardWordText>
        </ExperienceCard>
      </div>

      <div data-aos="fade-up">
        <CarouselContainer ref={carouselRef} onScroll={handleScroll}>
          <>
            {TimeLineData.map((item, index) => (
              <CarouselMobileScrollNode
                key={index}
                final={index === TOTAL_CAROUSEL_COUNT - 1}
              >
                <CarouselItem
                  index={index}
                  id={`carousel__item-${index}`}
                  active={activeItem}
                  onClick={(e) => handleClick(e, index)}
                >
                  <CarouselItemTitle>
                    {`${item.year}`}
                    <CarouselItemImg
                      width="208"
                      height="6"
                      viewBox="0 0 208 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.5 5.5C3.88071 5.5 5 4.38071 5 3V3.5L208 3.50002V2.50002L5 2.5V3C5 1.61929 3.88071 0.5 2.5 0.5C1.11929 0.5 0 1.61929 0 3C0 4.38071 1.11929 5.5 2.5 5.5Z"
                        fill="url(#paint0_linear)"
                        fillOpacity="0.33"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="-4.30412e-10"
                          y1="0.5"
                          x2="208"
                          y2="0.500295"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop
                            offset="0.79478"
                            stopColor="white"
                            stopOpacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </CarouselItemImg>
                  </CarouselItemTitle>
                  <CarouselItemText>{item.text}</CarouselItemText>
                </CarouselItem>
              </CarouselMobileScrollNode>
            ))}
          </>
        </CarouselContainer>
      </div>

      <CarouselButtons>
        {TimeLineData.map((item, index) => {
          return (
            <CarouselButton
              key={index}
              index={index}
              active={activeItem}
              onClick={(e) => handleClick(e, index)}
              type="button"
            >
              <CarouselButtonDot active={activeItem} />
            </CarouselButton>
          );
        })}
      </CarouselButtons>
    </Section>
  );
};

export default Timeline;
