import React from "react";

import { LogoContainer } from "./LogoStyles";

const Logo = () => (
  <LogoContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="175 0 200 350"
      width="50"
      height="50"
    >
      <path
        d="M203.95563,185.43808c0,-38.90214 31.53659,-70.43808 70.43879,-70.43808c16.40583,0 31.50165,5.60809 43.47458,15.01244c-9.11793,-5.07708 -19.61894,-7.96863 -30.79573,-7.96863c-35.0119,0 -63.39474,28.38232 -63.39474,63.39427c0,35.01197 28.38285,63.39474 63.39474,63.39474c11.17679,0 21.6778,-2.89227 30.79573,-7.96922c-11.97294,9.40448 -27.06876,15.01315 -43.47458,15.01315c-38.9022,0 -70.43879,-31.53652 -70.43879,-70.43867zM346.04437,185.43808c0,31.12179 -25.22904,56.35094 -56.35082,56.35094c-13.12472,0 -25.20136,-4.48694 -34.77976,-12.01055c7.29447,4.06154 15.69524,6.37538 24.6366,6.37538c28.00967,0 50.71577,-22.70622 50.71577,-50.71577c0,-28.00955 -22.70611,-50.71577 -50.71577,-50.71577c-8.94135,0 -17.34213,2.31384 -24.6366,6.37538c9.57839,-7.52361 21.65504,-12.01007 34.77976,-12.01007c31.12178,0 56.35082,25.22868 56.35082,56.35047zM267.83326,199.54379l0.28383,-46.12298c6.52818,0.28383 13.76594,0.14192 13.76594,0.14192c0,0 0.28383,42.43315 0,46.69066c-1.41917,22.56479 -34.06006,22.42287 -35.3373,2.55451c-0.14192,-2.55451 -0.14192,-5.10901 -0.14192,-7.66351l12.91444,0.14192v4.39943c0,5.81859 8.51502,8.08926 8.51502,-0.14192zM289.54654,153.42081c0,0 7.5216,0.14192 14.04977,0.14192l-0.99342,62.86919c0,0 -13.34019,-0.42575 -13.4821,-0.28383c0,-24.12587 0.42575,-55.63142 0.42575,-62.72727z"
        fill="#fff"
      />
    </svg>
  </LogoContainer>
);

export default Logo;
