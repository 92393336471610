import React from "react";
import { DiReact } from "react-icons/di";
import { TiHtml5 } from "react-icons/ti";
import { FaCss3Alt } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { SiTypescript } from "react-icons/si";
import { SiReactquery } from "react-icons/si";
import { TbBrandRedux } from "react-icons/tb";
import { TbBrandNextjs } from "react-icons/tb";
import { SiThealgorithms } from "react-icons/si";
import { IoLogoFirebase } from "react-icons/io5";
import { SiWebpack } from "react-icons/si";
import { SiJest } from "react-icons/si";
import { SiStorybook } from "react-icons/si";
import { FaAngular } from "react-icons/fa";
import { FaVuejs } from "react-icons/fa";
import { FaNpm } from "react-icons/fa";
import { SiTestinglibrary } from "react-icons/si";
import { FaSass } from "react-icons/fa";
import { FaGitSquare } from "react-icons/fa";
import { FaFigma } from "react-icons/fa";
import { SiStylelint } from "react-icons/si";
import { SiTailwindcss } from "react-icons/si";
import { FaBootstrap } from "react-icons/fa";
import { SiPrettier } from "react-icons/si";
import { SiEslint } from "react-icons/si";
import { SiVite } from "react-icons/si";
import { SiVitest } from "react-icons/si";
import { TbBrandCypress } from "react-icons/tb";
import { GrPerformance } from "react-icons/gr";
import { MdOutlineSecurity } from "react-icons/md";
import { FaUnity } from "react-icons/fa";
import { SiSocketdotio } from "react-icons/si";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaDocker } from "react-icons/fa";
import { SiI18Next } from "react-icons/si";
import { SiGithubactions } from "react-icons/si";

import {
  Section,
  SectionDivider,
  SectionText,
  SectionTitle,
} from "../../styles/GlobalComponents";

import {
  List,
  ListContainer,
  ListItem,
  ListParagraph,
  ListTitle,
} from "./TechnologiesStyles";

const Technologies = () => (
  <Section id="tech">
    <SectionDivider />
    <div data-aos="fade-down">
      <div style={{ marginTop: "10px" }} />
      <SectionTitle>Technologies</SectionTitle>
    </div>
    <div data-aos="fade-up">
      {/* <SectionText>
        I've worked with a range a technologies in the web development world.
        From Design To Front-End.
        <br />
        In 2020, I was accepted to study at the Faculty of Information Security
        of TUIT. I started learning Computer Science this year. <br />
        Technologies I've Learned: Foundation C++ ( Sep. 2020 - Feb. 2021 ).
        <br />
        Data structures and Algorithms ( Feb. 2021 - Aug. 2021 ).
        <br />
        Database, Cybersecurity fundamentals ( Sep. 2021 - Feb. 2022 ).
        <br />
        Algorithm Design, Web Programming, Computer Organization, Probability
        and Statistics. ( Feb. 2022 - Aug. 2022 )
        <br />
        UI / UX design ( Nov. 2021 - Jan. 2022 )
        <br />
        Frontend Programming ( Oct. 2021 - Present... )
      </SectionText> */}
    </div>
    <div data-aos="fade-up">
      <List>
        <ListItem>
          <DiReact size="6rem" />
          <ListTitle>React</ListTitle>
        </ListItem>

        <ListItem>
          <FaAngular size="6rem" />
          <ListTitle>Angular</ListTitle>
        </ListItem>

        <ListItem>
          <FaVuejs size="6rem" />
          <ListTitle>Vue</ListTitle>
        </ListItem>

        <ListItem>
          <IoLogoJavascript size="6rem" />
          <ListTitle>Javascript</ListTitle>
        </ListItem>

        <ListItem>
          <SiTypescript size="6rem" />
          <ListTitle>Typescript</ListTitle>
        </ListItem>

        <ListItem>
          <TiHtml5 size="6rem" />
          <ListTitle>HTML</ListTitle>
        </ListItem>

        <ListItem>
          <FaCss3Alt size="6rem" />
          <ListTitle>CSS</ListTitle>
        </ListItem>

        <ListItem>
          <FaSass size="6rem" />
          <ListTitle>SCSS</ListTitle>
        </ListItem>

        <ListItem>
          <SiTailwindcss size="6rem" />
          <ListTitle>Tailwind CSS</ListTitle>
        </ListItem>

        <ListItem>
          <FaBootstrap size="6rem" />
          <ListTitle>Bootstrap</ListTitle>
        </ListItem>

        <ListItem>
          <SiReactquery size="6rem" />
          <ListTitle>React Query</ListTitle>
        </ListItem>

        <ListItem>
          <TbBrandRedux size="6rem" />
          <ListTitle>Redux</ListTitle>
        </ListItem>

        <ListItem>
          <TbBrandNextjs size="6rem" />
          <ListTitle>Next</ListTitle>
        </ListItem>

        <ListItem>
          <SiThealgorithms size="6rem" />
          <ListTitle>Algorithms</ListTitle>
        </ListItem>

        <ListItem>
          <IoLogoFirebase size="6rem" />
          <ListTitle>Firebase</ListTitle>
        </ListItem>

        <ListItem>
          <SiWebpack size="6rem" />
          <ListTitle>Webpack</ListTitle>
        </ListItem>

        <ListItem>
          <SiJest size="6rem" />
          <ListTitle>Jest</ListTitle>
        </ListItem>

        <ListItem>
          <SiVitest size="6rem" />
          <ListTitle>Vitest</ListTitle>
        </ListItem>

        <ListItem>
          <TbBrandCypress size="6rem" />
          <ListTitle>Cypress</ListTitle>
        </ListItem>

        <ListItem>
          <MdOutlineScreenshotMonitor size="6rem" />
          <ListTitle>Screenshot test</ListTitle>
        </ListItem>

        <ListItem>
          <FaEye size="6rem" />
          <ListTitle>Loki</ListTitle>
        </ListItem>

        <ListItem>
          <FaDocker size="6rem" />
          <ListTitle>Docker</ListTitle>
        </ListItem>

        <ListItem>
          <SiTestinglibrary size="6rem" />
          <ListTitle>Testing library</ListTitle>
        </ListItem>

        <ListItem>
          <FaUnity size="6rem" />
          <ListTitle>Unity test</ListTitle>
        </ListItem>

        <ListItem>
          <SiStorybook size="6rem" />
          <ListTitle>Storybook</ListTitle>
        </ListItem>

        <ListItem>
          <SiI18Next size="6rem" />
          <ListTitle>I18Next</ListTitle>
        </ListItem>

        <ListItem>
          <FaGitSquare size="6rem" />
          <ListTitle>Git</ListTitle>
        </ListItem>

        <ListItem>
          <SiGithubactions size="6rem" />
          <ListTitle>Github actions</ListTitle>
        </ListItem>

        <ListItem>
          <FaFigma size="6rem" />
          <ListTitle>Figma</ListTitle>
        </ListItem>

        <ListItem>
          <FaNpm size="6rem" />
          <ListTitle>Npm</ListTitle>
        </ListItem>

        <ListItem>
          <SiVite size="6rem" />
          <ListTitle>Vite</ListTitle>
        </ListItem>

        <ListItem>
          <SiStylelint size="6rem" />
          <ListTitle>Stylelint</ListTitle>
        </ListItem>

        <ListItem>
          <SiEslint size="6rem" />
          <ListTitle>Eslint</ListTitle>
        </ListItem>

        <ListItem>
          <SiPrettier size="6rem" />
          <ListTitle>Prettier</ListTitle>
        </ListItem>

        <ListItem>
          <SiSocketdotio size="6rem" />
          <ListTitle>Socket</ListTitle>
        </ListItem>

        <ListItem>
          <GrPerformance size="6rem" />
          <ListTitle>Performance optimization</ListTitle>
        </ListItem>

        <ListItem>
          <MdOutlineSecurity size="6rem" />
          <ListTitle>Application security</ListTitle>
        </ListItem>
      </List>
    </div>

    <SectionDivider colorAlt />
  </Section>
);

export default Technologies;
