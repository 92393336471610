import React from "react";

import {
  ExperienceCard,
  ExperienceCardKompany,
  ExperienceCardKompanyLogo,
  ExperienceCardKompanyInfo,
  ExperienceCardKompanyInfoNames,
  ExperienceCardKompanyInfoNameKompany,
  ExperienceCardKompanyInfoJobPosition,
  ExperienceCardKompanyInfoTimeLine,
  ExperienceCardWordText,
  ExperienceCardKompanyInfoTimeLineMobile,
} from "./EducationStyles";

import tuitLogo from "../../Assets/tuit.jpeg";

import {
  Section,
  SectionDivider,
  SectionTitle,
} from "../../styles/GlobalComponents";

const Education = () => {
  return (
    <Section id="education">
      <div data-aos="fade-down">
        <SectionDivider />
        <div style={{ marginTop: "10px" }} />
        <SectionTitle>Education</SectionTitle>
      </div>

      <div data-aos="fade-up">
        <ExperienceCard>
          <ExperienceCardKompanyInfoTimeLineMobile>
            2020 - 2025
          </ExperienceCardKompanyInfoTimeLineMobile>
          <ExperienceCardKompany>
            <ExperienceCardKompanyLogo>
              <a href="https://tuit.uz/" target="_blank">
                <img src={tuitLogo} />
              </a>
            </ExperienceCardKompanyLogo>

            <ExperienceCardKompanyInfo>
              <ExperienceCardKompanyInfoNames>
                <ExperienceCardKompanyInfoJobPosition>
                  Tashkent University of Information Technologies named after
                  Muhammad al-Khwarizmi
                </ExperienceCardKompanyInfoJobPosition>

                <ExperienceCardKompanyInfoNameKompany>
                  BSc in Cybersecurity
                </ExperienceCardKompanyInfoNameKompany>
              </ExperienceCardKompanyInfoNames>
              <ExperienceCardKompanyInfoTimeLine>
                2020 - 2025
              </ExperienceCardKompanyInfoTimeLine>
            </ExperienceCardKompanyInfo>
          </ExperienceCardKompany>
          <ExperienceCardWordText>
            <ul>
              <li>
                <div /> I've worked with a range a technologies in the web
                development world. From Design To Front-End.
              </li>
              <li>
                <div /> In 2020, I was accepted to study at the Faculty of
                Information Security of TUIT. I started learning Computer
                Science this year. <br />
                Technologies I've Learned: Foundation C++ ( Sep. 2020 - Feb.
                2021 ).
              </li>
              <li>
                <div />
                Data structures and Algorithms ( Feb. 2021 - Aug. 2021 ).
              </li>
              <li>
                <div />
                Database, Cybersecurity fundamentals ( Sep. 2021 - Feb. 2022 ).
              </li>
              <li>
                <div />
                Algorithm Design, Web Programming, Computer Organization,
                Probability and Statistics.
              </li>
            </ul>
          </ExperienceCardWordText>
        </ExperienceCard>
      </div>
    </Section>
  );
};

export default Education;
