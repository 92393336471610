import React from "react";
import { Link, useHref } from "react-router-dom";

import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { BiPaperPlane } from "react-icons/bi";
import { DiCssdeck } from "react-icons/di";

import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import {
  Container,
  HeaderContainer,
  Div1,
  Div2,
  Div3,
  MenuBox,
  NavLink,
  SocialIcons,
  Span,
} from "./LayoutStyles";
import Logo from "../Components/Logo/Logo";
import Menu from "../Components/Menu/Menu";

export const Layout = ({ children }) => {
  // let hreff = useHref();

  return (
    <Container>
      <div data-aos="fade-down">
        <HeaderContainer>
          <Div1>
            <Logo />
          </Div1>

          <Div2>
            <li>
              <a href="#projects">
                <NavLink>Projects</NavLink>
              </a>
            </li>
            <li>
              <a href="#experience">
                <NavLink>Experience</NavLink>
              </a>
            </li>
            <li>
              <a href="#education">
                <NavLink>Education</NavLink>
              </a>
            </li>
            <li>
              <a href="#tech">
                <NavLink>Technologies</NavLink>
              </a>
            </li>
          </Div2>

          <Div3>
            <SocialIcons
              href="https://github.com/JasurbekIsokov"
              target="_blank"
            >
              <AiFillGithub size="3rem" />
            </SocialIcons>
            <SocialIcons
              href="https://www.linkedin.com/in/jasurbekisokov/"
              target="_blank"
            >
              <AiFillLinkedin size="3rem" />
            </SocialIcons>
            <SocialIcons href="https://t.me/JaSurBeK_IsoKoV" target="_blank">
              <BiPaperPlane size="3rem" />
            </SocialIcons>
          </Div3>

          <MenuBox>
            <Menu />
          </MenuBox>
        </HeaderContainer>
      </div>

      <main>{children}</main>
      <Footer />
    </Container>
  );
};
