import React, { useContext, useState } from "react";

import { RxHamburgerMenu } from "react-icons/rx";
import { BiPaperPlane } from "react-icons/bi";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import {
  Div1,
  Div2,
  NavLink,
  MenuContainer,
  SocialContainer,
  SocialIcons,
  HeaderContainer,
} from "./MenuStyles";
import Logo from "../Logo/Logo";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { AppContext } from "../../context/ContextProvider";

function Modal({ handleClose, open }) {
  useLockBodyScroll();

  return (
    <>
      <HeaderContainer>
        <Div1>
          <Logo />
        </Div1>

        <IoCloseSharp style={{ fontSize: "32px" }} onClick={handleClose} />
      </HeaderContainer>

      <Div2>
        <li onClick={handleClose}>
          <a href="#projects">
            <NavLink>Projects</NavLink>
          </a>
        </li>

        <li onClick={handleClose}>
          <a href="#experience">
            <NavLink>Experience</NavLink>
          </a>
        </li>

        <li onClick={handleClose}>
          <a href="#education">
            <NavLink>Education</NavLink>
          </a>
        </li>

        <li onClick={handleClose}>
          <a href="#certificates">
            <NavLink>Certificates</NavLink>
          </a>
        </li>

        <li onClick={handleClose}>
          <a href="#tech">
            <NavLink>Technologies</NavLink>
          </a>
        </li>

        <li onClick={handleClose}>
          <a href="#personal">
            <NavLink>Personal Achievements</NavLink>
          </a>
        </li>
      </Div2>

      <SocialContainer>
        <SocialIcons href="https://github.com/JasurbekIsokov" target="_blank">
          <AiFillGithub size="3rem" />
        </SocialIcons>
        <SocialIcons
          href="https://www.linkedin.com/in/jasurbekisokov/"
          target="_blank"
        >
          <AiFillLinkedin size="3rem" />
        </SocialIcons>
        <SocialIcons href="https://t.me/Jasurbek_Isokov" target="_blank">
          <BiPaperPlane size="3rem" />
        </SocialIcons>
      </SocialContainer>
    </>
  );
}

const Menu = () => {
  const { isOpen, setIsOpen } = useContext(AppContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <RxHamburgerMenu
        onClick={toggleMenu}
        style={{ fontSize: "28px", cursor: "pointer" }}
      />
      <MenuContainer open={isOpen}>
        {isOpen && <Modal handleClose={() => setIsOpen(false)} open={isOpen} />}
      </MenuContainer>
    </div>
  );
};

export default Menu;
