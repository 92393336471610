import styled from "styled-components";

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? "0" : "-100%")};
  /* right: 0; */
  width: ${({ open }) => (open ? "100%" : "0")};
  height: 100vh;
  background-color: #fff;
  transition: all 0.4s ease-in-out;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 100px;

  background-color: #0f1624;
  /* background-color: black; */
  cursor: pointer;

  div {
    z-index: 999;
  }
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  padding: 1rem;
  padding-top: 2rem;

  @media ${(props) => props.theme.breakpoints.sm} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 0px 20px;
  }
`;

export const Div1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding-top: 130px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NavLink = styled.a`
  font-size: 2rem;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.75);
  transition: 0.4s ease;
  &:hover {
    color: #fff;
    opacity: 1;
    cursor: pointer;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    padding: 0.5rem;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
`;

// Social Icons
export const SocialIcons = styled.a`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.3s ease;
  border-radius: 50px;

  &:hover {
    background-color: #212d45;
    transform: scale(1.2);
    cursor: pointer;
  }
`;
