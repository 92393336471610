import React, { useContext } from "react";

import { Layout } from "./layouts/Layout";
import Hero from "./Components/Hero/Hero";
import BgAnimation from "./Components/BackgrooundAnimation/BackgrooundAnimation";
import { Section } from "./styles/GlobalComponents";
import Projects from "./Components/Projects/Projects";
import Technologies from "./Components/Technologies/Technologies";
import Timeline from "./Components/TimeLine/TimeLine";
import Acomplishments from "./Components/Acomplishments/Acomplishments";

import { AppProvider } from "./context/ContextProvider";
import Education from "./Components/Education/Education";

function App() {
  return (
    <AppProvider>
      <Layout>
        <Section grid>
          <Hero />

          <BgAnimation />
        </Section>

        <Projects />

        <Timeline />

        <Education />

        <Acomplishments />

        <Technologies />
      </Layout>
    </AppProvider>
  );
}

export default App;
