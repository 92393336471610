import styled from "styled-components";

export const ImageContainer = styled.div`
  text-align: center;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    rgba(79, 108, 176, 0.25) 53.8%,
    rgba(79, 108, 176, 0) 100%
  );
  width: 100%;
  padding: 60px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    background-image: none;
    padding: 0;
    margin-top: 40px;
  }
  @media ${(props) => props.theme.breakpoints.md} {
    background-image: none;
    padding: 0;
    margin-top: 16px;
  }
`;

export const MainImage = styled.img`
  width: 100%;
`;

export const List = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin: 3rem 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin: 32px 0 64px 0;
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${(props) => props.theme.breakpoints.md} {
    margin: 20px 0 36px 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    margin: 20px 0 36px 0;
    gap: 10px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.sm} {
    display: flex;
    margin-left: 18px;
  }
`;

export const ListItem = styled.li`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.01);
    box-shadow: rgba(255, 255, 255, 1) 0px 1.5px 1.5px,
      rgba(255, 255, 255, 1) 0px 0px 1.5px 1.5px;

    p {
      color: #fff;
    }
  }

  @media ${(props) => props.theme.breakpoints.md} {
    max-width: 203px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    max-width: 320px;
    padding: 10px;
    border-radius: 10px;

    svg {
      height: 4rem !important;
      width: 4rem !important;
    }
  }
`;

export const ListTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 8px;

  @media ${(props) => props.theme.breakpoints.md} {
    font-size: 12px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    letter-spacing: 0.02em;
    margin-top: 2px;
  }
`;

export const ListParagraph = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.75);

  @media ${(props) => props.theme.breakpoints.md} {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ListIcon = styled.img`
  display: block;
  width: 48px;
  height: 48px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.breakpoints.md} {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    width: 32px;
    height: 32px;
    margin-bottom: 0px;
  }
`;
