import React, { useContext } from "react";

import { SectionText } from "../../styles/GlobalComponents";
import Button from "../../styles/GlobalComponents/Button";
import { LeftSection, SectionTitle, Section } from "./HeroStyles";
import { AppContext } from "../../context/ContextProvider";

const Hero = () => {
  const { isOpen } = useContext(AppContext);

  return (
    <Section row nopadding isOpen={isOpen}>
      <LeftSection>
        <div data-aos="fade-up">
          <SectionTitle main center>
            Jasurbek Isokov <br />
            Software Engineer
          </SectionTitle>
        </div>
        <div data-aos="fade-down">
          <SectionText>
            I'm Software Engineer. I am very interested in solving optimization
            problems and software architecture.
          </SectionText>
        </div>
        <div data-aos="fade-up">
          <Button>
            <a
              href="tel:+99-894-726-88-99"
              rel="noreferrer"
              style={{ color: "white" }}
            >
              Bog'lanish
            </a>
          </Button>
        </div>
      </LeftSection>
    </Section>
  );
};

export default Hero;
