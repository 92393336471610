import styled from "styled-components";

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  z-index: -1;
`;

export const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  padding: 1rem;
  place-items: center;
  column-gap: 1rem;
  row-gap: 1rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }
`;

export const BlogCard = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(80, 78, 78, 0.5);
  text-align: center;
  width: 500px;
  height: 400px;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  @media ${(props) => props.theme.breakpoints.lg} {
    width: 450px;
    height: 380px;
  }

  @media ${(props) => props.theme.breakpoints.md} {
    width: 90vw;
    height: 280px;
  }
`;

export const BlogCardBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  border-radius: 10px;
  transition: all 1s ease-in-out;

  background: linear-gradient(
    160.09deg,
    #141415 -12.64%,
    rgba(32, 45, 69, 1) -10%,
    rgba(32, 45, 69, 0.1) 74.29%
  );

  &:hover {
    background: linear-gradient(
        325.56deg,
        #00a795 -22.1%,
        rgba(32, 45, 69, 0.96875) -0.69%,
        rgba(32, 45, 69, 0) 33.13%
      ),
      linear-gradient(
        160.3deg,
        #00a795 -16.93%,
        rgba(32, 45, 69, 0.96875) -7.7%,
        rgba(32, 45, 69, 0) 48.72%
      );
  }
`;

export const HeaderThree = styled.h3`
  font-weight: 500;
  letter-spacing: 2px;
  color: #f0f0f0;
  padding: 0.5rem 0;
  text-align: left;
  margin: 20px;
  font-size: ${(props) => (props.title ? "3.5rem" : "2.5rem")};
`;
