import iqroQuran from "../Assets/images/iqro2.jpg";
import exim from "../Assets/images/exim.jpg";
import magicsoft from "../Assets/images/magicsoft2.jpg";
import medic from "../Assets/images/medical.jpg";
import tni from "../Assets/images/tni.jpg";
import supervision from "../Assets/images/supervision.jpg";
import homeMarket from "../Assets/images/home-market2.jpg";
import sevenResidence from "../Assets/images/seven-residence.jpg";

export const projects1 = [
  //   {
  //     title: "Information system cooperation in the field of library",
  //     description:
  //       "In the application you can: Open an account. You can sponsor libraries or add a new library. Functional parts: search, sort, activate or deactivate libraries. Add new libraries. Enter news. Monitor processes through the admin panel.",
  //     image: magicsoft,
  //     tags: ["Python", "Django", "CSS", "JavaScript", "Bootstrapt"],
  //     source: "http://dxshlibrary.pythonanywhere.com/",
  //     visit: "https://github.com/JasurbekIsokov/dxshlibrary",
  //     id: 0,
  //   },
  //   {
  //     title: "Of The Best Admin Dashboard",
  //     description:
  //       "Functional parts of the application: tracking, sorting, searching, changing and deactivating employees, customers and orders. Planning by calendar. Implementation of tasks at different stages. Monitoring inflation process through graphs. Compare products in the pyramid. Get complete revenue information.",
  //     image: adminPanel,
  //     tags: ["JavaScript", "ReactJs", "React Syncfusion"],
  //     source: "https://admin-dashboard-ji.vercel.app",
  //     visit: "https://github.com/JasurbekIsokov/Admin-Dashboard",
  //     id: 1,
  //   },
  //   {
  //     title: "House Market",
  //     description:
  //       "You can post an ad for rent or sale on the home market. From it you can open an account, provide information about the house (number of rooms, parking lot, address of the house...). You can edit, delete and add ads. You can contact the landlord to buy the house.",
  //     image: homeMarket,
  //     tags: ["ReactJs", "Firebase"],
  //     source: "https://home-market-ji.vercel.app/",
  //     visit: "https://github.com/JasurbekIsokov/House-Marketplace",
  //     id: 2,
  //   },
  //   // {
  //   //   title: "Headphones E-Commerce",
  //   //   description:
  //   //     "Headphones ECommerce praect has the following functional parts: you can view products, get information, learn about groups, add and delete. You can also add products to the cart and place an order. Sanity technology was used for the backend.",
  //   //   image: eCommerce,
  //   //   tags: ["ReactJs", "NextJs", "Sanity"],
  //   //   source: "https://e-commerce-ji.vercel.app/",
  //   //   visit: "https://github.com/JasurbekIsokov/ECommerce",
  //   //   id: 3,
  //   // },
  //   // {
  //   //   title: "Urologic Complex",
  //   //   description:
  //   //     "React and sass (scss) technologies were used in the urologic complex project. All code is written in a proprietary way and no additional libraries are used.",
  //   //   image: urologicComplex,
  //   //   tags: ["SCSS", "JavaScript", "ReactJs"],
  //   //   source: "https://urologic-complex-react.vercel.app/",
  //   //   visit: "https://github.com/JasurbekIsokov/Urologic-Complex-React",
  //   //   id: 4,
  //   // },
  //   {
  //     title: "GitHub Finder",
  //     description:
  //       "Github Finder a React app to search GitHub profiles and see profile details. Information we can see. User followers and following, all public repositories and public gists.",
  //     image: githubFinder,
  //     tags: ["JavaScript", "ReactJs"],
  //     source: "https://github-finder-mu-seven.vercel.app/",
  //     visit: "https://github.com/JasurbekIsokov/github-finder",
  //     id: 5,
  //   },
  //   {
  //     title: "Magic Life",
  //     description:
  //       "This project is built on html, css and scss (sass) technologies. It focuses on the creativity of the project and uses the best animations.",
  //     image: life,
  //     tags: ["HTML", "CSS", "SCSS"],
  //     source: "https://magiclife00.netlify.app/",
  //     visit: "https://github.com/JasurbekIsokov/MagicLife/tree/main/magic-life",
  //     id: 6,
  //   },
  //   {
  //     title: "Auto Restaurant",
  //     description:
  //       "This project is built on html, css, scss (sass) and javascript technologies. It focuses on the creativity of the project and uses the best animations.",
  //     image: restaurant,
  //     tags: ["HTML", "CSS", "JavaScript"],
  //     source: "https://auto-restaurant.vercel.app/",
  //     visit: "https://github.com/JasurbekIsokov/Gericht-Restaurant",
  //     id: 7,
  //   },
  //   {
  //     title: "Feedback App",
  //     description:
  //       "Project for feedback or to-do list. You can comment, rate, change, delete and add to it.",
  //     image: feedback,
  //     tags: ["Javascript", "ReactJs"],
  //     source: "https://feedback-app-ji.netlify.app/",
  //     visit: "https://github.com/JasurbekIsokov/Feedback-app",
  //     id: 8,
  //   },
];

export const TimeLineData = [
  {
    year: 2024,
    text: " Software Engineer at Magic Soft | Jan 2023 - Present",
  },
  {
    year: 2023,
    text: "Frontend Mentor White Line | Oct 2022 - April 2023",
  },
  {
    year: 2022,
    text: "Frontend Developer Intern Exadot | July 2022 - Sep 2022",
  },
  {
    year: 2021,
    text: " Frontend developer Intern Magic Soft | Nov 2021 - Jun 2022",
  },
];

export const projects = [
  {
    title: "Iqro Qur'an",
    description: "",
    image: iqroQuran,
    tags: [],
    source: "",
    visit: "https://iqro-quran.uz/",
    id: 1,
  },
  {
    title: "EximERP",
    description: "",
    image: exim,
    tags: [],
    source: "",
    visit: "https://eximerp.com/",
    id: 2,
  },
  {
    title: "MagicSoft.uz",
    description: "",
    image: magicsoft,
    tags: [],
    source: "",
    visit: "https://magicsoft.uz/",
    id: 3,
  },
  {
    title: "Medical system",
    description: "",
    image: medic,
    tags: [],
    source: "",
    visit: "http://med.magicsoft.uz/",
    id: 4,
  },
  {
    title: "TNI Asia",
    description: "",
    image: tni,
    tags: [],
    source: "",
    visit: "https://tniasia.net/",
    id: 5,
  },
  {
    title: "Supervision of employees",
    description: "",
    image: supervision,
    tags: [],
    source: "",
    visit: "http://face.magicsoft.uz/",
    id: 6,
  },
  {
    title: "Home market",
    description: "",
    image: homeMarket,
    tags: [],
    source: "",
    visit: "https://home-market-ji.vercel.app/",
    id: 7,
  },
  {
    title: "Seven residence",
    description: "",
    image: sevenResidence,
    tags: [],
    source: "",
    visit: "https://seven-residence.vercel.app/",
    id: 8,
  },
];
