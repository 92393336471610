import React, { useContext } from "react";

import {
  BlogCard,
  BlogCardBg,
  GridContainer,
  HeaderThree,
} from "./ProjectsStyles";

import {
  Section,
  SectionDivider,
  SectionTitle,
} from "../../styles/GlobalComponents";
import { projects } from "../../constants/constants";
import { AppContext } from "../../context/ContextProvider";

const Projects = () => {
  const { isOpen } = useContext(AppContext);

  return (
    <Section nopadding isOpen={isOpen} id="projects">
      <SectionDivider />

      <div data-aos="fade-down">
        <SectionTitle main>Projects</SectionTitle>
      </div>

      <GridContainer>
        {projects.map((project, i) => {
          return (
            <div data-aos="fade-up">
              <a href={project.visit} target="_blank" rel="noreferrer">
                <BlogCard
                  key={i}
                  style={{ backgroundImage: `url(${project.image})` }}
                >
                  <BlogCardBg>
                    <HeaderThree title>{project.title}</HeaderThree>
                  </BlogCardBg>
                </BlogCard>
              </a>
            </div>
          );
        })}
      </GridContainer>
    </Section>
  );
};

export default Projects;
